import { lazy, useState } from 'react';

import { useDebounce } from '~/hooks/use-debounce';

import styles from './styles.module.css';

import { MarkdownViewer } from './markdown-viewer';

const MDEditor = lazy(() => import('@uiw/react-md-editor'));

export const MarkdownEditor = ({
  text,
  height,
  onChange,
}: {
  height?: number;
  text: string;
  onChange: (text: string) => void;
}) => {
  const [localText, setLocalText] = useState(text);
  const [showPreview, setShowPreview] = useState(false);

  const saveText = async (description: string) => {
    debouncedRequest();
    setLocalText(description);
  };

  const debouncedRequest = useDebounce(() => {
    onChange(localText);
  });

  const preview = {
    name: 'preview',
    keyCommand: 'preview',
    render: () => {
      return (
        <button
          aria-label="Preview"
          onClick={() => {
            setShowPreview(true);
          }}
        >
          Preview
        </button>
      );
    },
  };

  return !showPreview ? (
    <div data-color-mode="light">
      <MDEditor
        height={height || 300}
        value={localText}
        extraCommands={[preview]}
        preview="edit"
        commandsFilter={(command) => {
          if (['quote', 'code', 'codeBlock', 'comment', 'image', 'table', 'hr'].includes(command.name || '')) {
            return false;
          }
          return command;
        }}
        onChange={(desc) => {
          saveText(desc || '');
        }}
      />
    </div>
  ) : (
    <>
      <div className={styles.markdownWrapper}>
        <div className={styles.markdownToolbar}>
          <button onClick={() => setShowPreview(false)}>Edit</button>
        </div>
        <div className={styles.markdownContent}>
          <MarkdownViewer text={localText} />
        </div>
      </div>
    </>
  );
};
